@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,500;0,700;1,400;1,500;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 768px) {

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}

@media only screen and (min-width: 768px) {

  html,
  body,
  #root {
    height: 100%;
    margin: 0;
    overflow: hidden;
  }
}
:root {
  --ant-primary-color: rgb(0,146,63);
  --antd-wave-shadow-color: rgb(0,146,63);
  --ant-primary-color-hover: rgb(0,146,63);
  --ant-primary-color-active: rgb(0,146,63);
  --ant-primary-5: rgb(0,146,63);
  --ant-primary-color-outline: transparent;
}

* {
  font-family: 'Open Sans', sans-serif !important;
}

*:focus {
  outline: none;
}

.middle {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.align-center {
  align-items: center !important;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center !important;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.ant-spin-nested-loading {
  height: 100%;
  z-index: 1;
}

.ant-spin-container {
  height: 100%;
}

.ant-spin-nested-loading>div>.ant-spin {
  max-height: unset !important;
}

.icon-prefix {
  color: rgba(0, 0, 0, 0.25) !important;
  margin-right: 5px;
}

.ant-form-item-explain-error {
  font-size: 12px !important;
  margin-bottom: 10px;
  margin-top: 5px;
}

.h-100 {
  height: 100%;
}

.ant-tooltip-inner {
  font-size: 12px !important;
}

.ant-form-item-explain {
  font-size: 12px !important;
  margin-top: 10px;
  margin-bottom: 10px;
}

.padding-10 {
  padding: 10px;
}

.setting-wrapper {
  height: 100%;
  overflow-y: auto;
}

.ant-table-cell {
  font-size: 12px;
}

.ant-card {
  border-radius: 10px !important;
}

.ant-menu-title-content {
  font-size: 12px !important;
}
::-webkit-input-placeholder {
  font-size: 12px;
}
:-ms-input-placeholder {
  font-size: 12px;
}
::placeholder {
  font-size: 12px;
}

a > span{
  font-size: 12px;
}

.card-product-filter > .ant-card-body {
  display: flex;
  align-items: center;
  width: 100%;
}

.ant-select-selection-placeholder {
  font-size: 12px !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
  border-right: none !important;
}
.ant-btn  {
  font-size: 12px !important;
}
.ant-divider-inner-text {
  font-size: 12px;
}
.ant-layout-header {
  height: 55px !important;
  line-height: unset !important;
}
.ant-layout-header > span {
  font-size: 15px !important;
}
tr:hover {
  cursor: pointer;
}
.ant-form-item-label > label {
  font-size: 12px !important;
}
.card-form {
  margin: 20px !important;
}
.card-form > .ant-card-body {
  padding: 10px 0 0 0;
}
::-webkit-scrollbar-thumb {
  background-color: rgba(0,0,0,.2);
}

::-webkit-scrollbar-track {
  background: hsla(0,0%,100%,.1);
}
